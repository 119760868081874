.slidein {
  position: fixed;
  left: -13em;
  width: 13em;
  height: 100vh;
  top: 0vh;
  z-index: 3;
  border-top-right-radius: 0.2em;
  background-color: grey;
  border-color: black;

  -webkit-animation: slidein 1s forwards;
  -webkit-animation-delay: 0s;
  animation: slidein 1s forwards;
  animation-delay: 0s;

  background-color: rgba(89, 116, 119, 1);
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
  border-right: 0.02em solid rgb(46, 45, 45);
}

@-webkit-keyframes slidein {
  100% {
    left: 0em;
  }
}

@keyframes slidein {
  100% {
    left: 0em;
  }
}

.slideout {
  position: fixed;
  left: 0em;
  width: 13em;
  height: 100vh;
  top: 0vh;
  z-index: 3;
  border-top-right-radius: 0.2em;
  background-color: grey;
  border-color: black;

  -webkit-animation: slideout 1s forwards;
  -webkit-animation-delay: 0s;
  animation: slideout 1s forwards;
  animation-delay: 0s;

  background-color: rgba(89, 116, 119, 1);
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
  border-right: 0.02em solid rgb(46, 45, 45);
}

@-webkit-keyframes slideout {
  100% {
    left: -13em;
  }
}

@keyframes slideout {
  100% {
    left: -13em;
  }
}

@media only screen and (max-width: 600px) {
  .slidein {
    position: fixed;
    left: -104vw;
    width: 104vw;
    height: 100vh;
    top: 0vh;
    z-index: 3;
    border-top-right-radius: 0.2em;
    background-color: grey;
    border-color: black;

    -webkit-animation: slidein 1s forwards;
    -webkit-animation-delay: 0s;
    animation: slidein 1s forwards;
    animation-delay: 0s;

    background-color: rgba(89, 116, 119, 1);
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
    border-right: 0.02em solid rgb(46, 45, 45);
  }

  @-webkit-keyframes slidein {
    100% {
      left: 0em;
    }
  }

  @keyframes slidein {
    100% {
      left: 0em;
    }
  }

  .slideout {
    position: fixed;
    left: 0em;
    width: 104vw;
    height: 100vh;
    top: 0vh;
    z-index: 3;
    border-top-right-radius: 0.2em;
    background-color: grey;
    border-color: black;

    -webkit-animation: slideout 1s forwards;
    -webkit-animation-delay: 0s;
    animation: slideout 1s forwards;
    animation-delay: 0s;

    background-color: rgba(89, 116, 119, 1);
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
    border-right: 0.02em solid rgb(46, 45, 45);
  }

  @-webkit-keyframes slideout {
    100% {
      left: -104vw;
    }
  }

  @keyframes slideout {
    100% {
      left: -104vw;
    }
  }
}

.dropdownelement {
}

.dropdownelement:hover {
  background-color: rgba(89, 116, 119, 0.97);
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.first-navbarelement {
  margin-top: 8vh;
  margin-bottom: 0vh;
}

.navbarelement {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.last-navbarelement {
  margin-top: 1vh;
  margin-bottom: 7vh;
}

.verylast-navbarelement {
  margin-top: 3em;
  margin-bottom: 3vh;
}

/* .last-dropdownelement {
  background-color: rgba(111, 154, 160, 0.97);
  border-bottom-right-radius: 0.2em;

  border-right: 0.02em solid rgb(46, 45, 45);

  background-color: rgba(89, 116, 119, 0.97);
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.last-dropdownelement:hover {
  background-color: rgba(111, 154, 160, 0.97);
  border-bottom-right-radius: 0.2em;

  border-right: 0.02em solid rgb(46, 45, 45);

  background-color: rgba(89, 116, 119, 0.97);
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%239C92AC' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
} */

.rotatebuttonlogo {
  -webkit-animation: rotate 4s both;
  -webkit-animation-delay: 0s;
  animation: rotate 4s both;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.rotatelefticondown {
  -webkit-animation: rotate 4s both;
  -webkit-animation-delay: 0s;
  animation: rotate90 1s both;
  animation-delay: 0s;
  animation-iteration-count: forward;
}

@keyframes rotate90 {
  100% {
    transform: rotate(90deg);
  }
}

.rotatelefticonright {
  -webkit-animation: rotate 4s both;
  -webkit-animation-delay: 0s;
  animation: rotateback90 1s both;
  animation-delay: 0s;
  animation-iteration-count: forward;
}

@keyframes rotateback90 {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.rotaterighticondown {
  -webkit-animation: rotate 4s both;
  -webkit-animation-delay: 0s;
  animation: rotate-90 1s both;
  animation-delay: 0s;
  animation-iteration-count: forward;
}

@keyframes rotate-90 {
  100% {
    transform: rotate(-90deg);
  }
}

.rotaterighticonleft {
  -webkit-animation: rotate 4s both;
  -webkit-animation-delay: 0s;
  animation: rotateback-90 1s both;
  animation-delay: 0s;
  animation-iteration-count: forward;
}

@keyframes rotateback-90 {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.navicon {
  padding-top: 3.5em;
  margin-left: -1em;
  z-index: 20;
  position: fixed;
}
