.marginIconRight_0 {
  margin-right: 0.7em;
}

.marginIconRight_1 {
  margin-right: 0.8em;
}

.marginIconRight_2 {
  margin-right: 0.5em;
}

.marginIconRight_3 {
  margin-right: 0.45em;
}
.marginIconRight_4 {
  margin-right: 0.55em;
}

.margin_mail {
  margin-right: 0.25em;
}

.margin_phone {
  margin-right: 0.25em;
}

.margin_whatsapp {
  margin-right: 0.25em;
}

.margintop {
  margin-top: 0.2em;
}

.marginbottom {
  margin-bottom: 0.2em;
}

.lg_margintop {
  margin-top: 0.4em;
}

.lg_marginbottom {
  margin-bottom: 0.4em;
}

.xlg_margintop {
  margin-top: 0.6em;
}

.xlg_marginbottom {
  margin-bottom: 1em;
}
/*
p {
  font-size: larger;
}*/

/* Icon pulse */
.fa-pulse {
  display: inline-block;
  -moz-animation: pulse 2s infinite linear;
  -o-animation: pulse 2s infinite linear;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fa-bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 1s infinite linear;
  -o-animation: bounce 1s infinite linear;
  -webkit-animation: bounce 1s infinite linear;
  animation: bounce 1s infinite linear;
}

@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-ms-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}

.carousel-indicators {
  z-index: 1;
}
