.imagebackgroundPCB {
  background-image: url("../Pictures/PCB.jpg");
  background-repeat: no-repeat, repeat;
  background-size: auto 100%;
}

.imagebackgroundStudie {
  background-image: url("../Pictures/formel.jpg");
  background-repeat: no-repeat, repeat;
  background-size: auto 100%;
}

.imagebackgroundSonstiges {
  background-image: url("../Pictures/formel2.jpg");
  background-repeat: no-repeat, repeat;
  background-size: auto 100%;
}

.imagePosition {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  background-image: url("../Pictures/detectposition.png");
  background-repeat: no-repeat, repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: "center";
  border-radius: 18px;
  border: 2px solid white;
  min-height: 110px;
}

.imagePosition:hover {
  filter: invert(100%);
  cursor: pointer;
}

.imageAnalyse {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  background-image: url("../Pictures/datenanalyse.png");
  background-repeat: no-repeat, repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: "center";
  border-radius: 18px;
  border: 2px solid white;
  min-height: 110px;
}

.imageAnalyse:hover {
  filter: invert(100%);
  cursor: pointer;
}

.mmborder {
  /*margin-top: auto;*/
  /*margin-bottom: auto;*/
  margin-left: auto;
  margin-right: auto;
  text-align: "center";
  background-color: black;
  color: white;
  border-radius: 18px;
  border: 2px solid white;
}

.mmborder:hover {
  color: lightblue;
  cursor: pointer;
  border: 2px solid lightblue;
}

.lineV {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 2px;
  width: 100%;
  background-color: white;
}

.lineH {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 200%;
  width: 2px;
  background-color: white;
  opacity: 1;
}

.textnotizH {
  color: lightgray;
  margin-left: 4px;
}

.textnotizH:hover {
  color: lightblue;
  cursor: pointer;
}

.textnotizV {
  color: lightgray;
  margin-left: -12px;
}

.textnotizV:hover {
  color: lightblue;
  cursor: pointer;
}

.textbox {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1em;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.further_text {
  font-size: 1em;
  margin-left: 0em;
  margin-right: 0em;
  margin-top: -0.25em;
  margin-bottom: -0.25em;
}

.textbox:hover {
  color: lightblue;
  cursor: pointer;
  border: lightblue;
}

.backgroundtransparent {
  background-color: grey;
}

@media only screen and (max-width: 600px) {
  .textbox {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 0.7em;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .further_text {
    font-size: 0.8em;
  }

  .textnotizH {
    color: lightgray;
    margin-left: 4px;
    font-size: 0.7em;
  }

  .textnotizV {
    color: lightgray;
    margin-left: -12px;
    font-size: 0.7em;
  }
}
