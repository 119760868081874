.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #1851c4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #09d3ac;
}

.radarImage {
  background-image: url("./components/Pictures/Brain2.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  /*background-size: cover; /* Resize the background image to cover the entire container */
}

.kontaktimage {
  border-radius: 2em !important;
  margin-left: 5em;
}
