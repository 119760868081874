@font-face {
  font-family: "angl";
  src: url(angltrr.ttf);
}

@font-face {
  font-family: "afb";
  src: url(AGENCYR.TTF);
}

body {
  /* font-family: "angl", arial; */
  /* font-family: "afb", arial; */
}

h2 {
  font-family: "afb", arial;
}

.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
