.cursor-pointer {
  cursor: pointer;
}

.nav-item {
  border-bottom: 1px solid white;
}

.nav {
  border-bottom: 1px solid white;
}

.cursor-pointer-lightblue:hover {
  cursor: pointer;
  color: lightblue;
}

.cursor-pointer-invert:hover {
  cursor: pointer;
  filter: invert(100%);
}

.table {
  width: 80%;
  margin: 0 auto !important;
  height: 60vh;
}

.navbar-icon:hover {
  cursor: pointer;
  color: lightblue;
 
}
